import gql from "graphql-tag";

import blob from "apis/fragments/blob";

export default gql`
  fragment vehicle on Vehicle {
    id
    name
    vehicleType
    fiscalPower
    electric
    yearlyTravelledKilometres
    insuranceProof {
      ...blob
    }
    registrationCard {
      ...blob
    }
  }
  ${blob}
`;
