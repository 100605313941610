import Router from "next/router";

import GET_EXPENSE, { GetExpenseData } from "apis/queries/getExpense";
import GET_INVOICE, { InvoiceData } from "apis/queries/invoice";
import { CategoryIconTypes } from "constants/categoryIconTypes";
import { Expense, Invoice, Notification } from "types";
import pushToSailorPage from "utils/notifications";
import { pushToTarget } from "utils/updateURLQuery";
import { fullName } from "utils/users";

export const TYPES_CATEGORIES: Record<string, CategoryIconTypes> = {
  signed_expense_report: "expense",
  sent_expense_report: "expense",
  accepted_expense: "expense",
  refused_expense: "expense",
  commented_expense: "expense",
  sailor_commented_expense: "expense",
  paid_payment: "payment",
  payslip_available: "payment",
  sent_invoice: "invoice",
  settled_invoice: "invoice",
  admin_settled_invoice: "invoice",
  signed_activity_report: "activity",
  signed_service_contract: "document",
  signed_service_contract_amendment: "document",
  sent_membership_agreement: "document",
  sent_work_contract: "document",
  sent_service_contract: "document",
  updated_document: "document",
  employee_committee_service_enabled: "service",
  employee_committee_service_renewed: "service",
  employee_savings_service_enabled: "service",
  onboarded_sailor: "sailor",
  christmas_voucher_service_enabled: "service",
  culture_voucher_service_enabled: "service",
  lunch_vouchers_service_enabled: "service",
  end_of_mission: "mission",
  referral_accepted: "referral",
  referral_godfather_success: "referral",
  referral_godchild_success: "referral",
  work_contract_trial_end: "activity",
  sailor_note: "document",
};

export const NOTIFICATION_RECORD_QUERIES = {
  sailor_commented_expense: GET_EXPENSE,
  commented_expense: GET_EXPENSE,
  refused_expense: GET_EXPENSE,
  admin_settled_invoice: GET_INVOICE,
};

export type GetNotificationRecord = GetExpenseData | InvoiceData;

export const adminNotificationRoute = (
  notification: Notification,
  record?: Expense | Invoice
) => {
  const { type, sailor } = notification;
  switch (type) {
    case "sailor_commented_expense":
      if (sailor)
        pushToSailorPage(sailor.id, {
          newTab: false,
          params: record
            ? {
                expenseReportID: (record as Expense).expenseReport.id,
                expenseID: record.id,
                action: "comments",
              }
            : undefined,
          page: "expenses",
        });
      break;
    case "signed_service_contract_amendment":
      if (sailor)
        pushToSailorPage(sailor.id, {
          newTab: false,
          params: { type: "contracts" },
        });
      break;
    case "admin_settled_invoice":
      pushToTarget("/crew/invoices", {
        newTab: false,
        query: record
          ? {
              search: fullName(sailor),
              state: "settled",
              invoiceID: record.id,
            }
          : undefined,
      });
      break;
    case "updated_document":
      if (sailor)
        pushToSailorPage(sailor.id, {
          newTab: false,
          params: {
            tab: "2",
          },
          page: "informations",
        });
      break;
    case "sailor_note":
      if (sailor)
        pushToSailorPage(sailor.id, {
          newTab: false,
          page: "notes",
        });
      break;
    default:
      break;
  }
};

export const notificationRoute = (
  notification: Notification,
  record?: Expense | Invoice
) => {
  switch (notification.type) {
    case "employee_committee_service_enabled":
    case "employee_committee_service_renewed":
    case "christmas_voucher_service_enabled":
      pushToTarget("/profile", { newTab: false, query: { tab: "3" } });
      break;
    case "signed_expense_report":
    case "sent_expense_report":
    case "refused_expense":
      pushToTarget("/expenses", {
        newTab: false,
        query: record
          ? {
              expenseReportID: (record as Expense).expenseReport.id,
            }
          : undefined,
      });
      break;
    case "commented_expense":
      pushToTarget("/expenses", {
        newTab: false,
        query: record
          ? {
              expenseReportID: (record as Expense).expenseReport.id,
              expenseID: record.id,
              action: "comments",
            }
          : undefined,
      });
      break;
    case "paid_payment":
    case "payslip_available":
      Router.push("/payslips", `/payslips`);
      break;
    case "signed_service_contract":
    case "signed_service_contract_amendment":
    case "sent_membership_agreement":
    case "sent_work_contract":
    case "sent_service_contract":
      Router.push("/contracts", `/contracts`);
      break;
    case "sent_invoice":
    case "settled_invoice":
      Router.push("/invoices", `/invoices`);
      break;
    case "signed_activity_report":
      Router.push("/activities", `/activities`);
      break;
    default:
      break;
  }
};
