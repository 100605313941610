import gql from "graphql-tag";

import expense from "apis/fragments/expense";
import { Expense } from "types";

export type GetExpenseData = {
  node: Expense;
};

export default gql`
  query getExpense($id: ID!) {
    node(id: $id) {
      ... on Expense {
        expenseReport {
          id
          state
        }
        ...expense
      }
    }
  }
  ${expense}
`;
