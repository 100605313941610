import gql from "graphql-tag";

import address from "apis/fragments/address";
import blob from "apis/fragments/blob";
import comment from "apis/fragments/comment";
import vehicle from "apis/fragments/vehicle";

export default gql`
  fragment expense on Expense {
    id
    billedOn
    amount
    frequency
    category
    expenseType
    state
    createdAt
    proof {
      ...blob
    }
    comments {
      ...comment
    }
    vehicle {
      ...vehicle
    }
    origin {
      ...address
    }
    destination {
      ...address
    }
    tripType
    distance
    totalKilometres
    effectiveDates
  }
  ${blob}
  ${comment}
  ${vehicle}
  ${address}
`;
