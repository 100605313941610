import Router from "next/router";

import {
  SimulationApiFormValues,
  SimulationApiResults,
} from "apis/getSimulation";

type TodoQueryValues = {
  id?: string;
  type?: "contracts";
  tab?: number;
  action?: "informations" | "expenses" | "payments";
  expenseReportID?: string;
  search?: string;
  state?: "new" | "pending" | "paid" | "cancelled";
  invoiceID?: string;
  sailor?: string;
  payableType?: "activity_report";
};

type QueryParams =
  | SimulationApiFormValues
  | SimulationApiResults
  | TodoQueryValues;

const toSnakeCase = (value: string) =>
  value
    .replace(/\W+/g, " ")
    .split(/ |\B(?=[A-Z])/)
    .map((word) => word.toLowerCase())
    .join("_")
    .replace("_i_d", "ID");

export const toQueryParams = (values: QueryParams) =>
  Object.keys(values)
    .map((key) => `${toSnakeCase(key)}=${values[key as keyof QueryParams]}`)
    .join("&");

export const pushToTarget = (
  pathname: string,
  options?: {
    newTab?: boolean;
    query?: Record<string, string>;
  }
) => {
  const newTab = options?.newTab;
  const query = options?.query;
  if (newTab && query) {
    window.open(`${pathname}?${toQueryParams(query)}`, "_blank");
  } else {
    Router.push({
      pathname,
      query,
    });
  }
};

const urlWithQS = (url: string, qs: string | URLSearchParams) =>
  `${url}${qs.toString().length > 0 ? "?" : ""}${qs}`;

const updateURLQuery = (
  params?: Record<string, string> | string,
  routerMethod: "push" | "replace" = "push"
) => {
  let nonEmptyParams = params;
  if (typeof params === "object") {
    nonEmptyParams = Object.fromEntries(
      Object.entries(params).filter((entry) => Boolean(entry[1]))
    );
  }

  const qs = new URLSearchParams(nonEmptyParams);

  if (typeof window !== "undefined") {
    const strippedAsPath = Router.asPath.replace(/\?.*$/, "");

    Router[routerMethod](urlWithQS(strippedAsPath, qs));
  }
};

export default updateURLQuery;
