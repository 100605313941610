import gql from "graphql-tag";

import blob from "apis/fragments/blob";

export default gql`
  fragment invoice on Invoice {
    id
    issuedOn
    dueDate
    month
    createdAt
    scheduledOn
    amountExclTax
    amountInclTax
    invoiceType
    state
    reference
    description
    remainingAmountDue
    comment
    sailor {
      id
      firstName
      lastName
    }
    client {
      id
      name
      joinReports
      mandatoryInvoicesNote
      vatPercent
    }
    mission {
      id
      name
      paymentPeriod
      endOfMonthPayment
      invoicesNote
    }
    file {
      ...blob
      previewImageUrl
    }
  }
  ${blob}
`;
