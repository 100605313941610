import { pushToTarget } from "./updateURLQuery";

const pushToSailorPage = (
  sailorId: string,
  options?: {
    newTab?: boolean;
    params?: Record<string, string>;
    page?: string;
  }
) => {
  pushToTarget(
    `/crew/sailors/[id]${options?.page ? `/${options?.page}` : ""}`,
    {
      newTab: options?.newTab,
      query: {
        id: sailorId,
        ...options?.params,
      },
    }
  );
};

export default pushToSailorPage;
