import gql from "graphql-tag";

export default gql`
  fragment blob on Blob {
    url
    filename
    contentType
    signedId
    createdAt
    byteSize
  }
`;
