import { useState } from "react";
import { ExpandMoreOutlined, ExpandLessOutlined } from "@mui/icons-material";
import { Box, BoxProps, Paper, Collapse, PaperProps } from "@mui/material";

interface StandardPaperProps
  extends Pick<PaperProps, "children">,
    Pick<BoxProps, "sx"> {
  expandableContent?: React.ReactElement;
  id?: string;
  height?: string;
}

const StandardPaper = ({
  children,
  expandableContent,
  id,
  height,
  sx,
}: StandardPaperProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpansion = () => setIsExpanded((prevValue) => !prevValue);

  const expandableButton = () => {
    const Icon = isExpanded ? ExpandLessOutlined : ExpandMoreOutlined;

    return (
      <Icon
        sx={{
          position: "absolute",
          bottom: 8,
          alignSelf: "center",
          cursor: "pointer",
        }}
        color="primary"
        onClick={toggleExpansion}
      />
    );
  };

  return (
    <Paper
      id={id}
      sx={{
        display: "flex",
        position: "relative",
        flexDirection: "column",
        overflow: "hidden",
        overflowWrap: "break-word",
        borderRadius: 2,
        height,
        pb: expandableContent ? 4 : 0,
      }}
    >
      <Box
        sx={{
          p: ({ spacing }) => ({
            xs: spacing(2, 2, 3, 2),
            md: spacing(2, 3, 3, 3),
          }),
          ...sx,
        }}
      >
        {children}
        {expandableContent && (
          <Collapse in={isExpanded}>{expandableContent}</Collapse>
        )}
      </Box>
      {Boolean(expandableContent) && expandableButton()}
    </Paper>
  );
};

export default StandardPaper;
