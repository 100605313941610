import {
  addDays,
  format,
  isThisWeek,
  isWeekend,
  formatDuration,
  differenceInYears,
  differenceInMonths,
  Locale,
} from "date-fns";
import { fr } from "date-fns/locale";
import { utcToZonedTime } from "date-fns-tz";

const defaultOptions: Intl.DateTimeFormatOptions = {
  day: "numeric",
  month: "numeric",
  year: "numeric",
};

export const longDateOptions: Intl.DateTimeFormatOptions = {
  day: "numeric",
  month: "long",
  year: "numeric",
};

export const isInCurrentWeek = (date: string) =>
  isThisWeek(new Date(Date.parse(date)));

export const areSameDay = (d1: Date, d2: Date) =>
  d1.getFullYear() === d2.getFullYear() &&
  d1.getMonth() === d2.getMonth() &&
  d1.getDate() === d2.getDate();

export const utcToParisTime = (d: Date | string) =>
  utcToZonedTime(d, "Europe/Paris");

export const formatDate = (value?: string | Date, options = defaultOptions) => {
  if (!value) return "";

  return (
    typeof value === "string" ? utcToParisTime(new Date(value)) : value
  ).toLocaleDateString("fr-FR", options);
};

export const dateToUTC = (d: Date) =>
  new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours()));

export const monthNames: () => string[] = () => {
  const months = [];
  for (let i = 0; i < 12; i += 1) months[i] = fr.localize?.month(i);
  return months;
};

export const dateToParisMidday = (d: Date = new Date()) =>
  utcToParisTime(new Date(d.setHours(12, 0, 0, 0)));

export const formatQueryDate = (date: Date) =>
  formatDate(date).replaceAll(/\//gi, "-");

export const getPeriodFromString = (date: string) => {
  const month = date.substring(3, 5);
  const year = date.substring(6);

  return new Date(`${year}-${month}-01`);
};

export const holidayOrWeekendInRange = (
  startDate: Date,
  endDate: Date,
  holidays?: string[]
) => {
  const dayTypes: Record<string, boolean[]> = {
    holiday: [],
    weekend: [],
  };
  let currentDate = startDate;
  while (currentDate <= endDate) {
    dayTypes.holiday.push(
      !holidays || holidays.includes(format(currentDate, "yyyy-MM-dd"))
    );
    dayTypes.weekend.push(isWeekend(currentDate));
    currentDate = addDays(currentDate, 1);
  }

  return Object.keys(dayTypes).filter((k) => dayTypes[k].includes(true));
};

export const getDateArrayFromRange = (start: Date, end: Date) => {
  const array = [];
  const date = new Date(start);

  while (date <= end) {
    array.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }

  return array;
};

export const distanceToNowInUnits = (date: Date, locale: Locale = fr) =>
  formatDuration(
    {
      years: differenceInYears(new Date(), date),
      months: differenceInMonths(new Date(), date) % 12,
    },
    { locale }
  );

export const isDateBefore = (initialDate: Date, dateToCompare: Date) =>
  new Date(initialDate).setHours(1, 0, 0, 0) <
  new Date(dateToCompare).setHours(1, 0, 0, 0);
