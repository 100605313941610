import { useRouter } from "next/router";
import { AppBar, Box, Tab, Tabs, Toolbar, Typography } from "@mui/material";

import BackButton from "components/BackButton";
import CategoryIcon from "components/BadgeIcon";
import TotalCount from "components/TotalCount";
import { CategoryIconTypes } from "constants/categoryIconTypes";

interface PageAppBarBasicProps {
  category?: CategoryIconTypes;
  tabsProps?: {
    tabs: { label: string; type: string }[];
    tabIdx: number;
    onChange: (idx: number) => void;
  };
  backButton?: { url: string; as?: string };
}

interface PageAppBarWithTitleProps extends PageAppBarBasicProps {
  title: string;
  subtitle?: string;
  totalCount?: number;
  actions?: React.ReactNode;
  children?: never;
}

interface PageAppBarWithChildrenProps extends PageAppBarBasicProps {
  children: React.ReactChild;
  title?: never;
  subtitle?: never;
  totalCount?: never;
  actions?: never;
}

type PageAppBarProps = PageAppBarWithTitleProps | PageAppBarWithChildrenProps;

const PageAppBar = ({
  title,
  subtitle,
  totalCount,
  category,
  tabsProps,
  actions,
  backButton,
  children,
}: PageAppBarProps) => {
  const router = useRouter();

  const backTo = () => {
    if (!backButton) return;
    if (backButton.as) router.push(backButton.url, backButton.as);
    else router.push(backButton.url);
  };

  return (
    <AppBar
      elevation={0}
      position="static"
      sx={{
        backgroundColor: "background.paper",
        color: "text.primary",
      }}
    >
      <Toolbar
        disableGutters
        sx={{
          px: { xs: 2, lg: 5 },
          pt: 2,
          flexDirection: "column",
          alignItems: "flex-start",
          borderBottom: ({ palette }) => `2px solid ${palette.grey[200]}`,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            width: "100%",
            pb: 2,
          }}
        >
          {backButton && (
            <Box sx={{ my: "auto" }}>
              <BackButton
                onClick={backTo}
                sx={{
                  color: "grey.600",
                  width: 24,
                  minWidth: "unset",
                  textAlign: "center",
                }}
              />
            </Box>
          )}
          <Box
            sx={{
              flexGrow: 1,
            }}
          >
            {children || (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  rowGap: 2,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {category && <CategoryIcon type={category} blue />}
                  <Typography variant="h5">
                    {title}{" "}
                    {totalCount ? <TotalCount count={totalCount} /> : ""}
                  </Typography>
                  {subtitle && (
                    <Typography
                      variant="bodySmall"
                      sx={{
                        mt: 0.5,
                        color: "grey.500",
                      }}
                    >
                      {subtitle}
                    </Typography>
                  )}
                </Box>
                {actions}
              </Box>
            )}
          </Box>
        </Box>
        {tabsProps && (
          <Tabs
            value={tabsProps.tabIdx}
            variant="scrollable"
            onChange={(_e: React.ChangeEvent<{}>, newTabIndex: number) =>
              tabsProps.onChange(newTabIndex)
            }
            aria-label={category ? `${category} type tabs` : "tabs"}
            sx={{ width: "100%", boxShadow: "none" }}
          >
            {tabsProps.tabs.map(({ type, label }) => (
              <Tab key={type} label={label} />
            ))}
          </Tabs>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default PageAppBar;
