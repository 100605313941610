import gql from "graphql-tag";

import invoice from "apis/fragments/invoice";
import { Invoice } from "types";

export type InvoiceData = {
  node: Invoice;
};

export default gql`
  query invoice($id: ID!) {
    node(id: $id) {
      ...invoice
      ... on Invoice {
        activityReports {
          id
          month
          format
          invoiceComment
          state
          totalBilled
          activities {
            id
            averageDailyRate
            duration
            durationUnit
            note
            startsOn
          }
        }
        expenseReports {
          id
          month
          totalAmount
          totalPackageAmount
          expenseReportType
          format
          invoiceComment
          state
          expensesStatesCount {
            accepted
          }
        }
        mission {
          id
          dailyExpensePackage
        }
      }
    }
  }
  ${invoice}
`;
