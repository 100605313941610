import gql from "graphql-tag";

import notification from "apis/fragments/notification";
import { Notification } from "types";

export type UserNotificationsData = {
  node: {
    unreadNotificationsCount: number;
    notifications: {
      edges: Array<{ node: Notification }>;
      pageInfo: {
        endCursor: string;
        hasNextPage: boolean;
      };
    };
  };
};

export default gql`
  query getUserNotifications(
    $userID: ID!
    $unread: Boolean
    $sailorID: ID
    $first: Int
    $after: String
  ) {
    node(id: $userID) {
      ... on Sailor {
        id
        unreadNotificationsCount
        notifications(
          unread: $unread
          sailorId: $sailorID
          first: $first
          after: $after
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              ...notification
            }
          }
        }
      }
      ... on AccountManager {
        id
        unreadNotificationsCount(sailorId: $sailorID)
        notifications(
          unread: $unread
          sailorId: $sailorID
          first: $first
          after: $after
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              ...notification
            }
          }
        }
      }
      ... on BusinessDeveloper {
        id
        unreadNotificationsCount(sailorId: $sailorID)
        notifications(
          unread: $unread
          sailorId: $sailorID
          first: $first
          after: $after
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              ...notification
            }
          }
        }
      }
      ... on ClientAdmin {
        id
        unreadNotificationsCount(sailorId: $sailorID)
        notifications(
          unread: $unread
          sailorId: $sailorID
          first: $first
          after: $after
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          edges {
            node {
              ...notification
            }
          }
        }
      }
    }
  }
  ${notification}
`;
