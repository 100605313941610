const formatNumber = (value?: number, options = {}) =>
  value || value === 0
    ? value.toLocaleString("fr-FR", {
        minimumFractionDigits: Number.isInteger(value) ? 0 : 2,
        ...options,
      })
    : "";

export const formatPercentage = (value?: number, options = {}) =>
  value || value === 0
    ? formatNumber(value, {
        style: "percent",
        maximumFractionDigits: 2,
        ...options,
      })
    : "";

export const formatCurrency = (value?: number | "", options = {}) =>
  value || value === 0
    ? formatNumber(value, {
        style: "currency",
        currency: "EUR",
        ...options,
      })
    : "";

export const formatKilometres = (value?: number | "", options = {}) =>
  value || value === 0
    ? formatNumber(value, {
        style: "unit",
        unit: "kilometer",
        ...options,
      })
    : "";

export const formatCurrencyWithSign = (value: number, options = {}) =>
  (value > 0 ? "+" : "") +
  formatCurrency(value, { style: "currency", currency: "EUR", ...options });

export const formatBytes = (bytes: number): string => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "n/a";
  const i = Math.min(
    Math.floor(Math.log(bytes) / Math.log(1024)),
    sizes.length - 1
  );
  if (i === 0) return `${bytes} ${sizes[i]}`;
  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
};

export default formatNumber;
