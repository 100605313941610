import gql from "graphql-tag";

export default gql`
  fragment comment on Comment {
    id
    content
    createdAt
    author {
      id
      role
      firstName
      lastName
      profilePic
    }
  }
`;
