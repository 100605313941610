import {
  ButtonProps,
  IconButton as MuiIconButton,
  IconButtonProps as MuiIconButtonProps,
  styled,
  SvgIconTypeMap,
} from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

interface StyledIconButtonProps extends MuiIconButtonProps {
  variant: ButtonProps["variant"];
}

const StyledIconButton = styled(MuiIconButton, {
  shouldForwardProp: (prop) => prop !== "variant",
})<StyledIconButtonProps>(
  ({ theme: { palette, typography, spacing }, color, variant }) => ({
    ...(color === "primary" && {
      ...(variant === "contained" && {
        borderRadius: 8,
        color: palette.primary.contrastText,
        backgroundColor: palette.primary.main,
        "&:hover": {
          backgroundColor: palette.primary.dark,
        },
        "&.Mui-disabled": {
          color: palette.grey[600],
          backgroundColor: palette.grey[300],
        },
      }),
      ...(variant === "outlined" && {
        borderRadius: 8,
        color: palette.primary.main,
        border: "1px solid",
        borderColor: palette.primary.main,
        "&:hover": {
          backgroundColor: palette.primary.background,
          borderColor: palette.primary.main,
        },
        "&.Mui-disabled": {
          color: palette.grey[400],
          borderColor: palette.grey[400],
          backgroundColor: palette.grey[100],
        },
      }),
      ...(variant === "text" && {
        borderRadius: 8,
        color: palette.primary.main,
        "&:hover": {
          backgroundColor: palette.primary.background,
        },
        "&.Mui-disabled": {
          color: palette.grey[400],
        },
      }),
    }),
    sizeSmall: {
      ...typography.buttonSmall,
      padding: spacing(0.75),
    },
    sizeMedium: {
      padding: spacing(1),
    },
    sizeLarge: {
      ...typography.buttonLarge,
      padding: spacing(1.5),
    },
  })
);

export interface CustomIconButtonProps
  extends Omit<StyledIconButtonProps, "variant">,
    Pick<MuiIconButtonProps, "sx"> {
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  iconSize?: "small" | "large" | "medium";
  variant?: StyledIconButtonProps["variant"];
}

const CustomIconButton = ({
  icon: Icon,
  iconSize = "small",
  variant = "text",
  color = "primary",
  ...props
}: CustomIconButtonProps) => (
  <StyledIconButton variant={variant} color={color} size="small" {...props}>
    <Icon fontSize={iconSize} />
  </StyledIconButton>
);

export default CustomIconButton;
