import Image from "next/image";
import { Typography, Box } from "@mui/material";

export interface DataPlaceholderProps {
  title?: string;
  description?: string;
  imageSrc?: string;
  displayImage?: boolean;
}

const DataPlaceholder = ({
  title,
  description,
  imageSrc = "/images/lost-sailors.png",
  displayImage = true,
}: DataPlaceholderProps) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
      maxWidth: 400,
      mx: "auto",
    }}
  >
    {displayImage && (
      <Box
        sx={{
          maxWidth: 200,
        }}
      >
        <Image
          width={200}
          height={200}
          src={imageSrc}
          alt="Missing data placeholder image"
        />
      </Box>
    )}
    {title && (
      <Typography
        variant="label"
        sx={{
          color: "primary.main",
          pt: 2,
        }}
      >
        {title}
      </Typography>
    )}
    {description && (
      <Typography
        sx={{
          pt: 1,
          color: "grey.600",
        }}
      >
        {description}
      </Typography>
    )}
  </Box>
);

export default DataPlaceholder;
