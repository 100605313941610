import DOMPurify, { Config } from "dompurify";

const defaultOptions: Config = {
  ALLOWED_TAGS: ["b", "i", "em", "strong", "a", "p", "br", "img", "li"],
  ALLOWED_ATTR: ["href", "style", "src"],
  RETURN_DOM_FRAGMENT: false,
  RETURN_DOM: false,
};

const sanitize = (dirty: string, options?: Config) => ({
  __html: DOMPurify.sanitize(dirty, {
    ...defaultOptions,
    ...options,
  }) as string,
});

export const sanitizeToString = (dirty: string, options?: Config) =>
  // eslint-disable-next-line no-underscore-dangle
  sanitize(dirty, options).__html;

export default sanitize;
