import gql from "graphql-tag";

export default gql`
  fragment notification on Notification {
    id
    createdAt
    readAt
    message
    type
    recordId
    sailor {
      id
      firstName
      lastName
      profilePic
    }
  }
`;
