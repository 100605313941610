import gql from "graphql-tag";

export type ReadNotificationsData = {
  readNotifications: {
    ok: boolean;
  };
};

export default gql`
  mutation readNotifications(
    $userID: ID!
    $ids: [ID!]
    $all: Boolean
    $sailorID: ID
  ) {
    readNotifications(
      input: {
        userId: $userID
        notificationIds: $ids
        all: $all
        sailorId: $sailorID
      }
    ) {
      ok
    }
  }
`;
