import { Typography, Box } from "@mui/material";

const TotalCount = ({ count }: { count: number }) => (
  <Box
    component="span"
    sx={{
      color: "grey.600",
    }}
  >
    <Typography component="span" variant="inherit">
      ({count})
    </Typography>
  </Box>
);

export default TotalCount;
